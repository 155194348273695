@import '../mixins';

.highCardContainer {
  width: 388px;
  height: 580px;
  position: relative !important;
  border-radius: 24px;
  box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12);
  @include mobile {
    width: 100%;
    height: 580px;
    border-radius: 20px;
  }

  &_header {
    width: 388px;
    height: 260px;
    position: relative;
    border-radius: 24px;
    @include mobile {
      width: 100%;
      height: 260px;
      border-radius: 20px;
    }
    img {
      width: 388px;
      height: 260px;
      object-fit: cover;
      object-position: center center;
      border-radius: 24px;
      @include mobile {
        width: 100%;
        height: 260px;
        border-radius: 20px;
      }
    }
    &_details {
      position: absolute;
      width: calc(100%);
      height: calc(100%);
      border-radius: 24px;
      top: 0;
      left: 0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_top {
        @include center-between;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        h3 {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $--main-text;
          gap: 5px;
          height: max-content;

          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          padding: 0px 8px;
          display: flex;
          flex-direction: column;
          padding: 5px 10px;
          gap: 2px;

          span {
            span {
              color: $--primary;
            }
            &:last-of-type {
              span {
                color: var(--stock-green);
              }
            }
          }
        }
        p {
          height: 23px;
          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          @include center;
          padding: 0 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: $--main-text;
        }
        &_price {
          width: max-content;
          height: 36px;
          background: rgba(254, 254, 255, 0.8);
          padding: 0 15px;
          @include center;
          border-radius: 20px;

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            span {
              color: $--primary;
            }
          }
        }
        &_type {
          width: max-content;
          height: max-content;
          background: $--primary-light;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;
          max-width: 80%;
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--primary;
            }
          }
        }
        &_days {
          width: max-content;
          height: max-content;
          background: $--secondary-accent;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--stock-green;
            }
          }
        }
      }
      &_bot {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          color: $--card-main;
        }
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: $--card-main;
        }
      }
    }
  }

  &_main {
    height: 300px;
    width: 388px;
    background-color: $--card-main;
    padding: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @include mobile {
      width: 100%;
      height: 310px;
      padding: 16px;
    }
    &_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &_texts {
        display: flex;
        align-items: center;
        gap: 5px;
        &_img {
          width: 46px;
          height: 46px;
          min-height: 46px;
          min-width: 46px;
          background-color: #fff;
          border-radius: 50%;
          padding: 4px;
          border: 1px solid #f3f4ff;
          @include center;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            object-position: center center !important;
            @include center;
            text-align: center !important;
          }
        }
        div {
          h2 {
            font-weight: 600;
            font-size: 15px;
            width: 310px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
            color: $--main-text;
            @include mobile {
              width: 240px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          span {
            font-style: normal;
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $--sec-text;
            @include mobile {
              font-size: 12px;
            }
          }
        }
      }
      &_numbers {
        display: flex;
        align-items: center;
        @include center-between;
        span {
          font-style: normal;
          font-weight: 500;

          font-size: 11px;
          line-height: 14px;
          display: flex;

          gap: 5px;
          align-items: center;
          white-space: nowrap;

          p {
            font-style: normal;
            font-weight: 500;

            max-width: 160px;
            display: block !important;
            font-size: 11px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 14px;
            display: flex;
            gap: 5px;

            color: $--main-text;
            @include mobile {
              max-width: 110px;
            }
          }
          @include mobile {
            font-size: 10px;
          }
        }
        p {
          color: $--sec-text;
          font-weight: 500;
          font-size: 12px;
          @include mobile {
            font-size: 10px;
          }
        }
        &_completion {
          border-radius: 10px;
          background: rgba(224, 255, 216, 0.6);
          // backdrop-filter: blur(12px);
          justify-content: center;
          display: flex;
          min-height: 30px;
          align-items: center;
          flex-grow: 1;

          padding: 4px 10px;
          span {
            color: #00764b !important;

            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      &_completion {
        border-radius: 10px;
        background: rgba(224, 255, 216, 0.6);
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: #00764b !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_sold {
        display: flex;
        justify-content: space-between;

        align-items: center;
        gap: 8px;
        &_price {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: #f3f4ff;
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--primary;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
        }
        &_date {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: var(--grey-white, #fefeff);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--sec-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
        &_type {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 10px;
          background: #f8f8f9;
          // backdrop-filter: blur(12px);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
          p {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
    &_divider {
      width: 100%;
      border-top: 1px solid $--secondary-accent;
      // margin: 6px 0;
    }
    &_agent {
      width: calc(388px - 32px);
      position: absolute;
      bottom: 0;
      left: 16px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid $--secondary-accent;
      gap: 10px;
      padding-top: 10px;
      @include mobile {
        width: calc(300px - 32px);
        gap: 10px;
        padding: 0 0;
        padding-top: 10px;
      }
      &_top {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;
        section {
          position: absolute;
          top: 24px;
          left: 25px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          background-color: #fff;
          @include center;
          svg {
            width: 16px;
            height: 16px;

            color: #00764b !important;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          font-weight: bold;
          cursor: pointer;
          @include mobile {
            width: 38px;
            height: 38px;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            text-align: center;
          }
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          white-space: nowrap;
          text-align: center;
          text-transform: capitalize;
          margin-right: 20px;
          &:first-of-type {
            text-align: left;
            width: 190px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          @include mobile {
            white-space: nowrap;
            &:first-of-type {
              width: 160px;
            }
          }
        }
        div {
          @include center;
          min-width: 25px !important;
          height: 25px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 5px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
      &_bottom {
        display: flex;
        max-height: 20px;

        gap: 5px;
        justify-content: center;
        @include mobile {
          max-height: 30px;
        }
        a {
          text-align: center;

          display: flex;

          gap: 10px;
          font-size: 12px;
          white-space: nowrap;
          @include mobile {
            font-size: 11px;
          }
          h3 {
            font-size: 12px;
            text-decoration: underline;
            white-space: pre;
            color: #d12d34;
            text-align: start;
            white-space: pre-line;
            @include mobile {
              font-size: 11px;
            }
          }
        }
        p {
          color: #9b99ae;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
        div {
          @include center;
          min-width: 32px !important;
          height: 32px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 5px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.fcardContainer {
  width: 388px;
  height: 580px;
  position: relative !important;
  border-radius: 24px;
  box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12);
  @include mobile {
    width: 300px;
    height: 570px;
    border-radius: 20px;
  }

  &_header {
    width: 388px;
    height: 260px;
    position: relative;
    border-radius: 24px;
    @include mobile {
      width: 300px;
      height: 260px;
      border-radius: 20px;
    }
    img {
      width: 388px;
      height: 260px;
      object-fit: cover;
      object-position: center center;
      border-radius: 24px;
      @include mobile {
        width: 300px;
        height: 260px;
        border-radius: 20px;
      }
    }
    &_details {
      position: absolute;
      width: calc(100%);
      height: calc(100%);
      border-radius: 24px;
      top: 0;
      left: 0;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &_top {
        @include center-between;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        h3 {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $--main-text;
          gap: 5px;
          height: max-content;

          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          padding: 0px 8px;
          display: flex;
          flex-direction: column;
          padding: 5px 10px;
          gap: 2px;

          span {
            span {
              color: $--primary;
            }
            &:last-of-type {
              span {
                color: var(--stock-green);
              }
            }
          }
        }
        p {
          height: 23px;
          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          @include center;
          padding: 0 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: $--main-text;
        }
        &_price {
          width: max-content;
          height: 36px;
          background: rgba(254, 254, 255, 0.8);
          padding: 0 15px;
          @include center;
          border-radius: 20px;

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            span {
              color: $--primary;
            }
          }
        }
        &_type {
          width: max-content;
          height: max-content;
          background: $--primary-light;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--primary;
            }
          }
        }
        &_days {
          width: max-content;
          height: max-content;
          background: $--secondary-accent;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--stock-green;
            }
          }
        }
      }
      &_bot {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          color: $--card-main;
        }
        h2 {
          font-style: normal;
          text-align: end;
          font-weight: 600;
          font-size: 12px;
          text-align: end;
          align-self: flex-end;
          width: max-content;
          padding: 0 10px;
          border-radius: 8px;
          line-height: 16px;
          color: $--main-text;
          background-color: $--card-main;
        }
      }
    }
  }

  &_main {
    height: 300px;
    width: 388px;
    background-color: $--card-main;
    padding: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @include mobile {
      width: 300px;
      height: 295px;
      padding: 16px;
    }
    &_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &_texts {
        display: flex;
        align-items: center;
        gap: 5px;
        &_img {
          width: 46px;
          height: 46px;
          min-height: 46px;
          min-width: 46px;
          background-color: #fff;
          border-radius: 50%;
          padding: 4px;
          border: 1px solid #f3f4ff;
          @include center;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            object-position: center center !important;
            @include center;
            text-align: center !important;
          }
        }
        div {
          h2 {
            font-weight: 600;
            font-size: 15px;
            width: 310px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
            color: $--main-text;
            @include mobile {
              width: 240px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          span {
            font-style: normal;
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $--sec-text;
            @include mobile {
              font-size: 12px;
            }
          }
        }
      }
      &_numbers {
        display: flex;
        align-items: center;
        @include center-between;
        span {
          font-style: normal;
          font-weight: 500;

          font-size: 11px;
          line-height: 14px;
          display: flex;
          gap: 5px;
          align-items: center;
          white-space: nowrap;
          p {
            font-style: normal;
            font-weight: 500;
            max-width: 160px;
            display: block !important;
            font-size: 11px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 14px;
            display: flex;
            gap: 5px;

            color: $--main-text;
            @include mobile {
              max-width: 110px;
            }
          }
          @include mobile {
            font-size: 10px;
          }
        }
        p {
          color: $--sec-text;
          font-weight: 500;
          font-size: 12px;
          @include mobile {
            font-size: 10px;
          }
        }
        &_completion {
          border-radius: 10px;
          background: rgba(224, 255, 216, 0.6);
          // backdrop-filter: blur(12px);
          justify-content: center;
          display: flex;
          min-height: 30px;
          align-items: center;
          flex-grow: 1;

          padding: 4px 10px;
          span {
            color: #00764b !important;

            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      &_completion {
        border-radius: 10px;
        background: rgba(224, 255, 216, 0.6);
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: #00764b !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_history {
        border-radius: 10px;
        background: $--primary-light;
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: $--primary !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_unit {
        border-radius: 10px;
        background: #f3f4ff;
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: $--main-text !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_sold {
        display: flex;
        justify-content: space-between;

        align-items: center;
        gap: 8px;
        &_price {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: #f3f4ff;
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--primary;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
        }
        &_date {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: var(--grey-white, #fefeff);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--sec-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
        &_type {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 10px;
          background: #f8f8f9;
          // backdrop-filter: blur(12px);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
          p {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
    &_divider {
      width: 100%;
      border-top: 1px solid $--secondary-accent;
      // margin: 6px 0;
    }
    &_agent {
      width: calc(388px - 32px);
      position: absolute;
      bottom: 0;
      left: 16px;
      display: flex;
      flex-direction: column;
      border-top: 1px solid $--secondary-accent;
      gap: 10px;
      padding-top: 10px;
      @include mobile {
        width: calc(300px - 32px);
        gap: 10px;
        padding: 0 0;
        padding-top: 10px;
      }
      &_top {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;
        section {
          position: absolute;
          top: 24px;
          left: 25px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          background-color: #fff;
          @include center;
          svg {
            width: 16px;
            height: 16px;

            color: #00764b !important;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          font-weight: bold;
          cursor: pointer;
          @include mobile {
            width: 38px;
            height: 38px;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            text-align: center;
          }
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          white-space: nowrap;
          text-align: center;
          text-transform: capitalize;
          margin-right: 20px;
          &:first-of-type {
            text-align: left;
            width: 190px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          @include mobile {
            white-space: nowrap;
            &:first-of-type {
              width: 160px;
            }
          }
        }
        div {
          @include center;
          min-width: 25px !important;
          height: 25px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 5px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
      &_bottom {
        display: flex;
        max-height: 20px;

        gap: 5px;
        justify-content: center;
        @include mobile {
          max-height: 30px;
        }
        a {
          text-align: center;

          display: flex;

          gap: 10px;
          font-size: 12px;
          white-space: nowrap;
          @include mobile {
            font-size: 11px;
          }
          h3 {
            font-size: 12px;
            text-decoration: underline;
            white-space: pre;
            color: #d12d34;
            text-align: start;
            white-space: pre-line;
            @include mobile {
              font-size: 11px;
            }
          }
        }
        p {
          color: #9b99ae;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
        div {
          @include center;
          min-width: 32px !important;
          height: 32px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 5px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.agentCardContainer {
  width: 388px;
  min-height: 580px;
  height: max-content;
  position: relative !important;
  position: relative;
  border-radius: 24px;
  box-shadow: -4px 24px 38px rgba(161, 173, 205, 0.12);
  @include mobile {
    width: 310px;
    min-height: 570px;
    height: max-content;
    border-radius: 20px;
  }
  &_edits {
    position: absolute;
    bottom: 5px;
    cursor: pointer;
    right: 8px;
    display: flex;
    align-items: center;
  }
  &_header {
    width: 388px;
    height: 200px;
    position: relative;
    border-radius: 24px;
    @include mobile {
      width: 310px;
      height: 180px;
      border-radius: 20px;
    }
    img {
      width: 388px;
      height: 200px;
      object-fit: cover;
      object-position: center center;
      border-radius: 24px;
      @include mobile {
        width: 310px;
        height: 180px;
        border-radius: 20px;
      }
    }
    &_details {
      position: absolute;
      width: calc(100%);
      height: calc(100%);
      border-radius: 24px;
      top: 0;
      left: 0;
      padding: 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mobile {
        padding: 12px;
      }
      &_top {
        @include center-between;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        h3 {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $--main-text;
          gap: 5px;
          height: max-content;

          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          padding: 0px 8px;
          display: flex;
          flex-direction: column;
          padding: 5px 10px;
          gap: 2px;

          span {
            span {
              color: $--primary;
            }
            &:last-of-type {
              span {
                color: var(--stock-green);
              }
            }
          }
        }
        p {
          height: 23px;
          background: rgba(224, 255, 216, 0.8);
          border-radius: 20px;
          @include center;
          padding: 0 6px;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: $--main-text;
        }
        &_price {
          width: max-content;
          height: 36px;
          background: rgba(254, 254, 255, 0.8);
          padding: 0 15px;
          @include center;
          border-radius: 20px;

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            span {
              color: $--primary;
            }
          }
        }
        &_type {
          width: max-content;
          height: max-content;
          background: $--primary-light;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--primary;
            }
          }
        }
        &_days {
          width: max-content;
          height: max-content;
          background: $--secondary-accent;
          padding: 2px 15px;
          @include center;
          border-radius: 10px;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            span {
              color: $--stock-green;
            }
          }
        }
      }
      &_bot {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          color: $--card-main;
        }
        h2 {
          font-style: normal;
          text-align: end;
          font-weight: 600;
          font-size: 12px;
          text-align: end;
          align-self: flex-end;
          width: max-content;
          padding: 0 10px;
          border-radius: 8px;
          line-height: 16px;
          color: $--main-text;
          background-color: $--card-main;
        }
      }
      &_hotdeals {
        position: absolute;
        bottom: -10px;
        right: 0;
        background-color: #d12d34;
        z-index: 1;
        span {
          color: #fff;
          white-space: pre;
          background: linear-gradient(
            90deg,
            transparent 25%,
            $--primary-light 50%,
            transparent 75%
          );
          font-weight: 600;
          font-size: 14px;
          background-size: 200% 100%;
          animation: shimmer 3s infinite linear;
        }
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
      }
    }
  }

  &_main {
    min-height: 300px;
    height: max-content;
    width: 388px;
    background-color: $--card-main;
    padding: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @include mobile {
      width: 310px;
      min-height: 295px;
      height: max-content;
      padding: 10px 16px;
    }
    &_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &_texts {
        display: flex;
        align-items: center;
        gap: 5px;
        &_img {
          width: 46px;
          height: 46px;
          min-height: 46px;
          min-width: 46px;
          background-color: #fff;
          border-radius: 50%;
          padding: 4px;
          border: 1px solid #f3f4ff;
          @include center;
          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            object-position: center center !important;
            @include center;
            text-align: center !important;
          }
        }
        div {
          h2 {
            font-weight: 600;
            font-size: 15px;
            width: 310px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
            color: $--main-text;
            @include mobile {
              width: 240px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          span {
            font-style: normal;
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 4px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: $--sec-text;
            @include mobile {
              font-size: 12px;
            }
          }
        }
      }
      &_numbers {
        display: flex;
        align-items: center;
        @include center-between;
        gap: 10px;
        span {
          font-style: normal;
          font-weight: 500;

          font-size: 11px;
          line-height: 14px;
          display: flex;
          gap: 5px;
          align-items: center;
          white-space: nowrap;
          p {
            font-style: normal;
            font-weight: 500;
            max-width: 80px;
            display: block !important;
            font-size: 11px;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            line-height: 14px;
            display: flex;
            gap: 5px;

            color: $--main-text;
            @include mobile {
              max-width: 110px;
            }
          }
          @include mobile {
            font-size: 10px;
          }
        }
        p {
          color: $--sec-text;
          font-weight: 500;
          font-size: 12px;
          @include mobile {
            font-size: 10px;
          }
        }
        &_completion {
          border-radius: 10px;
          background: rgba(224, 255, 216, 0.6);
          // backdrop-filter: blur(12px);
          justify-content: center;
          display: flex;
          min-height: 30px;
          align-items: center;
          flex-grow: 1;
          max-width: 100px;
          padding: 4px 10px;
          span {
            color: #00764b !important;

            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      &_completion {
        border-radius: 10px;
        background: rgba(224, 255, 216, 0.6);
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: #00764b !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_deals {
        border-radius: 10px;
        background: $--primary-light;
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        background: linear-gradient(
          90deg,
          transparent 25%,
          $--primary-light 50%,
          transparent 65%
        );
        background-size: 200% 100%;
        border: 1px solid $--primary-light;
        animation: shimmer 3s infinite linear;
        span {
          color: $--primary !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
      }
      &_hotdeals {
        border-radius: 10px;

        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        background: linear-gradient(
          90deg,
          transparent 25%,
          rgba(224, 255, 216, 0.6) 50%,
          transparent 65%
        );
        background-size: 200% 100%;
        border: 1px solid rgba(224, 255, 216, 1);
        animation: shimmer 3s infinite linear;
        span {
          color: var(--stock-green);

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        @keyframes shimmer {
          0% {
            background-position: -200% 0;
          }
          100% {
            background-position: 200% 0;
          }
        }
      }
      &_history {
        border-radius: 10px;
        background: $--primary-light;
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: $--primary !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_edits {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        overflow: hidden;
        div {
          cursor: pointer;
          background: $--primary-light;
          &:last-of-type {
            background-color: rgba($color: #3043f0, $alpha: 0.4);
          }
          // backdrop-filter: blur(12px);
          justify-content: center;
          display: flex;
          min-height: 30px;
          align-items: center;
          flex-grow: 1;

          padding: 4px 10px;
          span {
            color: $--primary !important;

            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      &_unit {
        border-radius: 10px;
        background: #f3f4ff;
        // backdrop-filter: blur(12px);
        justify-content: center;
        display: flex;
        min-height: 30px;
        align-items: center;
        flex-grow: 1;

        padding: 4px 10px;
        span {
          color: $--main-text !important;

          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      &_sold {
        display: flex;
        justify-content: space-between;

        align-items: center;
        gap: 8px;
        &_price {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: #f3f4ff;
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--primary;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
        }
        &_date {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 8px;
          border: 1px solid rgba(155, 153, 174, 0.1);
          background: var(--grey-white, #fefeff);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
          }
          p {
            color: $--sec-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
        &_type {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          padding: 4px 8px;

          border-radius: 10px;
          background: #f8f8f9;
          // backdrop-filter: blur(12px);
          span {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
          p {
            color: $--main-text;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }
    &_divider {
      width: 100%;
      border-top: 1px solid $--secondary-accent;
      // margin: 6px 0;
    }
    &_agent {
      width: calc(388px - 32px);
      position: relative;

      display: flex;
      flex-direction: column;
      border-top: 1px solid $--secondary-accent;
      gap: 10px;
      padding-top: 10px;
      @include mobile {
        width: calc(300px - 32px);
        gap: 10px;
        padding: 0 0;
        padding-top: 10px;
      }
      &_top {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        gap: 10px;
        section {
          position: absolute;
          top: 24px;
          left: 25px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          background-color: #fff;
          @include center;
          svg {
            width: 16px;
            height: 16px;

            color: #00764b !important;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          font-weight: bold;
          cursor: pointer;
          @include mobile {
            width: 38px;
            height: 38px;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            text-align: center;
          }
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */
          white-space: nowrap;
          text-align: center;
          text-transform: capitalize;
          margin-right: 20px;
          @include mobile {
            white-space: nowrap;
          }
        }
        div {
          @include center;
          min-width: 25px !important;
          height: 25px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          margin-top: -30px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 0px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
      &_bottom {
        display: flex;
        max-height: 20px;

        gap: 5px;
        justify-content: center;
        @include mobile {
          max-height: 30px;
        }
        a {
          text-align: center;

          display: flex;

          gap: 10px;
          font-size: 12px;
          white-space: nowrap;
          @include mobile {
            font-size: 11px;
          }
          h3 {
            font-size: 12px;
            text-decoration: underline;
            white-space: pre;
            color: #d12d34;
            text-align: start;
            white-space: pre-line;
            @include mobile {
              font-size: 11px;
            }
          }
        }
        p {
          color: #9b99ae;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
        div {
          @include center;
          min-width: 32px !important;
          height: 32px;

          /* grey/white */
          cursor: pointer;
          background: --card-main;
          box-shadow: 0px 4px 34px rgba(156, 158, 188, 0.3);
          border-radius: 20px;
          padding: 5px;
          &:first-of-type {
            margin-left: auto;
          }
          &:last-of-type {
            margin-left: 5px;
          }
          @include mobile {
            min-width: 25px !important;
            height: 25px !important;
          }
          svg {
            color: $--primary;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.agentCardContainerv3 {
  width: 100%;
  max-width: 400px;
  border: 1px;
  border-radius: 24px;
  overflow: hidden;
  height: max-content;
  background-color: #2c2c2e;
  @include mobile {
    width: 100%;
  }
  &_top {
    width: 100%;

    display: flex;
    align-items: center;
    padding: 16px;
    padding-bottom: 0;
    @include mobile {
    }
    &_left {
      width: 100%;
      height: 185px;
      border-radius: 24px;
      position: relative;

      @include mobile {
        height: 185px;
        border-radius: 24px;
      }
      &_image {
        width: 100%;
        height: 185px;
        object-fit: cover;
        object-position: center center;
        border-radius: 24px;

        @include mobile {
          height: 100%;
          border-radius: 24px;
        }
      }
      aside {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 210px;
        border-radius: 24px;
        display: flex;
        // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.63%, #000 100%);
        border-radius: 21px;

        @include mobile {
          height: 210px;
          border-radius: 21px;
          flex-direction: column;
          gap: 60px;
          left: auto;
          right: 0;
        }
        main {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-top: auto;
          gap: 7px;
          height: 210px;
          padding: 5px 0px;
          a {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          button {
            display: flex;

            max-width: max-content;
            height: 22px;
            margin-right: 8px;
            padding: 0 19px;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            border: none;
            border-radius: 32px;
            outline: none;
            text-align: start;

            color: #fff;

            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background-color: rgba($color: #2c2c2e, $alpha: 0.5);

            backdrop-filter: blur(10px);
            align-items: center;
            span {
              color: #d12d34;
              font-size: 10px;
              font-weight: 500;
            }
            div {
              padding: 0;
              margin: 0;
              background: #fff;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              @include center;
              transform: rotate(-45deg);
            }
          }
          footer {
            display: flex;

            max-width: max-content;
            height: 20px;
            margin-right: 8px;
            padding: 0 10px;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            border: none;
            border-radius: 32px;
            outline: none;
            text-align: start;

            color: #f2f2f2;

            font-family: Montserrat;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            background-color: rgba($color: #23946b, $alpha: 0.8);

            backdrop-filter: blur(10px);
            align-items: center;
            span {
              color: #f2f2f2;
              font-size: 10px;
              font-weight: 500;
            }
            div {
              padding: 0;
              margin: 0;
              background: #fff;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              @include center;
              transform: rotate(-45deg);
            }
          }
        }
        div {
          margin-top: auto;
          display: flex;
          align-items: center;
          padding: 18px;
          gap: 6px;
          @include mobile {
            padding: 12px;
            margin-top: 10px;
          }
          img {
            width: 36px;
            height: 36px;
            min-height: 36px;
            min-width: 36px;
            background-color: #fff;
            border-radius: 50%;
            padding: 4px;
            border: 1px solid #f3f4ff;
          }
          section {
            display: flex;
            flex-direction: column;
            gap: 2px;
            span {
              color: var(--white, #fefeff);

              display: inline-block;
              font-family: Montserrat;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 114.286% */
              width: 200px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            p {
              color: var(--white, #fefeff);

              font-family: Montserrat;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
      &_overlay {
        position: absolute;

        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        bottom: 0;
        border-radius: 24px;
        background: rgba($color: #000000, $alpha: 0.4);

        &_top {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          padding-left: 8px;
          gap: 6px;
          &_first {
            width: max-content;
            // padding: 0 12px;
            height: 22px;
            background-color: rgba($color: #d12d34, $alpha: 0.6);
            border-radius: 20px;
            padding: 0 8px;
            @include center;
            span {
              font-family: Montserrat;
              font-size: 10px;
              font-weight: 600;
              line-height: 14.63px;
              text-align: left;
              color: #f2f2f2;
            }
          }
          &_second {
            width: 85px;
            height: 20px;
            border-radius: 20px;
            background-color: rgba($color: #e0ffd8, $alpha: 0.6);
            @include center;
            span {
              font-family: Montserrat;
              font-size: 10px;
              font-weight: 600;
              line-height: 17.07px;
              text-align: center;
              color: #000;
              span {
                color: #d12d34;
              }
            }
          }
        }
        &_bottom {
          width: 100%;
          display: flex;
          &_first {
            width: 100%;

            margin-top: auto;
            display: flex;
            align-items: center;
            padding: 18px;
            gap: 6px;
            @include mobile {
              padding: 8px;
              margin-top: 10px;
            }
            div {
              img {
                width: 36px;
                height: 36px;
                min-height: 36px;
                min-width: 36px;
                background-color: #fff;
                border-radius: 50%;
                // padding: 4px;
                // border: 1px solid #f3f4ff;
                &:last-of-type {
                  width: 16px !important;
                  height: 16px !important;
                  position: absolute;

                  min-height: 20px;
                  min-width: 20px;
                }
              }
            }

            section {
              display: flex;
              flex-direction: column;
              gap: 2px;
              span {
                color: var(--white, #fefeff);

                display: inline-block;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px; /* 114.286% */
                width: 200px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              p {
                color: var(--white, #fefeff);

                font-family: Montserrat;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
    &_right {
      width: 229px;
      height: 100%;
      border-radius: 8px;
      padding: 10px 14px;
      display: flex;
      border-top-right-radius: 24px;
      overflow: hidden;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      position: relative;

      @include mobile {
        padding: 0;
      }
      &_hotdeal {
        width: 108px;
        height: 97px;
        clip-path: polygon(100% 0, 0 0, 100% 100%);
        background-color: #d12d34;
        position: absolute;
        top: 0;
        display: flex;

        right: 0;

        span {
          margin-top: 15px;
          margin-left: 18px;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          color: #fff;
          transform: rotate(42deg);
        }
      }
      &_soldAt {
        width: 100%;
        height: 65px;
        box-shadow: -4px 24px 38px 0px #a1adcd1f;

        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // padding-left: 40px;
        span {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          color: #666478;
        }
        h3 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          color: #d12d34;
        }
      }
      &_lastSold {
        width: 100%;
        height: 65px;
        box-shadow: -4px 24px 38px 0px #a1adcd1f;

        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // padding-left: 40px;
        span {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          color: #666478;
        }
        h3 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          color: #000;
        }
      }

      &_dateSold {
        width: 100%;
        height: 65px;
        box-shadow: -4px 24px 38px 0px #a1adcd1f;

        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        // padding-left: 40px;
        span {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          color: #666478;
        }
        h3 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          color: #000;
        }
      }
      &_paymentPlan {
        width: 100%;
        height: 65px;
        box-shadow: -4px 24px 38px 0px #a1adcd1f;

        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_top {
          display: flex;
          justify-content: space-between;
          padding: 0 30px;
          span {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            color: #666478;
          }
          &_divider {
            height: 50%;
            border-left: 1px solid #d0d0d0;
            margin-top: 6px;
          }
        }
        &_bottom {
          display: flex;
          justify-content: center;
          padding: 10px 10px;
          span {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            color: #666478;
          }
        }
      }
    }
  }
  &_bottom {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-top: 14px;
    @include mobile {
      padding: 16px;
      padding-top: 14px;
      gap: 8px;
    }
    &_labels {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        background-color: #161616;
        border: none;
        border-radius: 1px;
        padding: 4px 8px;
        color: #f1f1f1;
        font-size: 10px;
        display: flex;

        align-items: center;
        gap: 5px;
      }
    }
    &_address {
      display: flex;
      flex-direction: column;
      gap: 8px;
      @include mobile {
      }
      h3 {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #f2f2f2;
        font-size: 16px;
        font-weight: 700;
        @include mobile {
          font-size: 14px;
          font-weight: 700;
          color: #f2f2f2;
        }
      }
      &_price {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-top: 4px;
        span {
          font-size: 14px;
          color: #d12d34;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            font-weight: 700;
            font-size: 14px;
            color: #f2f2f2;
          }
        }
      }
      &_location {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #969696;
        font-size: 15px;
        @include mobile {
          // margin: 4px 0;
          font-weight: 500;
          font-size: 12px;
          color: #969696;
        }
      }
    }

    &_status {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &_completed {
        border: solid 1px #000;
        background-color: #f0feea;

        border-color: #f0feea;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;

        width: 48%;
        height: 44px;
        margin-top: 14px;
        border-radius: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 600;
          line-height: 15.85px;
          text-align: center;
          color: #36744f;
          @include mobile {
            font-size: 10px;
          }
        }
      }
      &_primarysale {
        border: solid 1px #000;
        width: 48%;
        height: 44px;
        background-color: #f0feea;

        border-color: #f0feea;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;

        margin-top: 14px;
        border-radius: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 600;
          line-height: 15.85px;
          text-align: center;
          color: #666478;
          @include mobile {
            font-size: 10px;
          }
        }
      }
      &_exploresales {
        border: solid 1px #000;
        width: 48%;
        border-color: #d12d34;
        color: #d12d34;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;

        height: 44px;
        margin-top: 14px;
        border-radius: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 600;
          line-height: 15.85px;
          text-align: center;

          @include mobile {
            font-size: 10px;
          }
        }
      }
      &_data {
        border: solid 1px #000;
        width: 48%;
        height: 44px;
        margin-top: 14px;
        border-radius: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        span {
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 600;
          line-height: 15.85px;
          text-align: center;
          color: #666478;
          span {
            color: #d12d34;
          }
          @include mobile {
            font-size: 10px;
          }
        }
      }
    }
    &_homeland {
      width: 547px;
      height: 555px;
      top: 10555px;
      left: -2789px;
      gap: 0px;
      border: 1px 0px 0px 0px;
    }
  }
}
